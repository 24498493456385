html {
  background-color: #000;
}

.box {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Chrome, Safari 対応 */
.box::-webkit-scrollbar {
  display: none;
}

p {
  font-family: 'japan_l';
}

h2 {
  font-family: 'japan_l';
  color: #707070;
}

h3 {
  font-family: japan_b;
}

@font-face {
  font-family: 'dela';
  src: url(./fonts/DelaGothicOne-Regular.ttf);
}

.dela {
  font-family: dela;
}
@font-face {
  font-family: 'noto-m';
  src: url(./fonts/NotoSansJP-Medium.ttf);
}

.noto-m {
  font-family: noto-m;
}
@font-face {
  font-family: 'noto-bold';
  src: url(./fonts/NotoSansJP-Bold.ttf);
}

.noto-bold {
  font-family: noto-bold;
}
@font-face {
  font-family: 'noto-thin';
  src: url(./fonts/NotoSansJP-Thin.ttf);
}

.noto-reg {
  font-family: noto-reg;
}
@font-face {
  font-family: 'noto-reg';
  src: url(./fonts/NotoSansJP-Regular.ttf);
}

.noto-thin {
  font-family: noto-thin;
}

.menu_box {
  min-height: 80vh;
  min-height: 80dvh;
}

.no_copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
